// @flow

import type { OrganizationReport, OrganizationReportVatPayment, State } from 'types'

export function getDashboardOrganizationReport(state: State): ?OrganizationReport {
	return state.dashboard.currentTurnover.organizationReport
}

export function getOrganizationReportVatPayment(state: State): ?OrganizationReportVatPayment {
	const organizationReport = getDashboardOrganizationReport(state)
	return organizationReport && organizationReport.vatPayment
}

// @flow

import { type WithTranslateProps, withTranslate } from 'wrappers'

import React from 'react'
import { Snackbar } from 'components'

type Props = {|
	...WithTranslateProps,
	shouldBecomeVatPayer: boolean,
	shouldBecomeVatPayerNow: boolean,
	organizationVatPayerType: number,
|}

function VatRegistrationState({ t, shouldBecomeVatPayer, shouldBecomeVatPayerNow, organizationVatPayerType }: Props) {
	if (organizationVatPayerType === 0 || (!shouldBecomeVatPayer && !shouldBecomeVatPayerNow)) return null

	const message = shouldBecomeVatPayerNow
		? t('settings.administration.vatRegistrationNow')
		: t('settings.administration.vatRegistration')
	const type = shouldBecomeVatPayerNow ? 'error' : 'warn'

	return <Snackbar open persist showIcon type={type} message={message} />
}

export default withTranslate(VatRegistrationState)

/* @flow */
/** @jsx jsx */

import type {
	AccountingDocumentDirection,
	ActivationWithStartDate,
	Filter,
	OrganizationCountrySpecificSettingsCzAuthorization,
	OrganizationCountrySpecificSettingsCzTaxRegistration,
	OrganizationStatistics,
	OrganizationStatisticsCountrySpecificCz,
	OrganizationStatisticsKeysBankAccounts,
	OrganizationStatisticsKeysCashRegister,
	RendererArgType,
} from 'types'
import { Avatar, Button, Chip, Tooltip, TriviLink } from 'components'
import { type WithTranslateProps, withNotify, withTranslate } from 'wrappers'
import { formatOrganizationVATType, formatToDateString } from 'utils/formatters'

import CashRegistersTooltip from './cash-registers-tooltip'
import { Component } from 'react'
import ContractIcon from 'modules/common/components/contract-icon'
import DataGrid from 'modules/data-grid/containers/data-grid'
import DocumentsDirectionTooltip from './documents-direction-tooltip'
import ExtractedDocumentsDirectionTooltip from './extracted-documents-direction-tooltip'
import NeedHelpTooltip from './needHelp-tooltip'
import PaymentsTooltip from './payments-tooltip'
import StatementsSummary from './statements-summary'
import type { WithNotifyProps } from 'wrappers'
import { colors } from 'variables'
import { jsx } from '@emotion/core'
import { organizationsStats } from 'modules/common/models/api-model'
import styles from './organization-statistics-grid.css'

const TRANSLATION_PREFIX = 'internal.widget.organizationStatistics.'

const TRANSLATIONS = {
	statsHeader: TRANSLATION_PREFIX + 'header',
	organizationName: TRANSLATION_PREFIX + 'organizationName',
	accountingType: TRANSLATION_PREFIX + 'accountingType',
	vatPayerType: TRANSLATION_PREFIX + 'vatPayerType',
	draftCount: TRANSLATION_PREFIX + 'draftCount',
	extractedDrafts: TRANSLATION_PREFIX + 'extractedDrafts',
	extractedDraftsReceived: TRANSLATION_PREFIX + 'extractedDraftsReceived',
	extractedDraftsIssued: TRANSLATION_PREFIX + 'extractedDraftsIssued',
	draftsReceived: TRANSLATION_PREFIX + 'draftsReceived',
	draftsIssued: TRANSLATION_PREFIX + 'draftsIssued',
	unmatchedPayments: TRANSLATION_PREFIX + 'unmatchedPayments',
	errorCount: TRANSLATION_PREFIX + 'errorCount',
	fixingCount: TRANSLATION_PREFIX + 'fixingCount',
	travelOrders: TRANSLATION_PREFIX + 'travelOrders',
	bankStatements: TRANSLATION_PREFIX + 'bankStatements',
	unknowns: TRANSLATION_PREFIX + 'unknowns',
	beginExtraction: TRANSLATION_PREFIX + 'beginExtraction',
	nothingToExtract: TRANSLATION_PREFIX + 'nothingToExtract',
	processingWaiting: TRANSLATION_PREFIX + 'processingWaiting',
	needHelp: TRANSLATION_PREFIX + 'needHelp',
}

type Props = {|
	...WithNotifyProps,
	...WithTranslateProps,
	isInternalExactUser?: boolean,
	showExtract: boolean,
	accountingDocumentsCountToExtract: number,
	organizationsIds: ?Array<string>,
	filter: Filter,
	extractNextAccountingDocument: () => void,
	changeCurrentOrganization: (id: string) => Promise<void>,
	onFilterChange: (organizationID: string, direction: AccountingDocumentDirection, filter: Filter) => Promise<void>,
	redirectToNoProcessedStatements: (organizationId: string, bankAccountId: string) => Promise<void>,
	redirectToCashRegisterRoute: (organizationId: string, cashRegisterId: string, filter: Filter) => Promise<void>,
	redirectToUnmatchedPaymentsRoute: (organizationId?: string, filter: Filter) => Promise<void>,
	redirectToMatchedPaymentsRoute: (organizationId?: string, filter: Filter) => Promise<void>,
|}

class OrganizationStatisticsGrid extends Component<Props> {
	gridRefresh: Function = () => {}

	getStatRender = (field: string) => ({ row }: RendererArgType) => {
		const val = row.stats == null ? 0 : row.stats[field] || 0
		return <div css={style.cell}>{val}</div>
	}

	getColumns() {
		const { t, redirectToNoProcessedStatements } = this.props

		return {
			accountingType: {
				name: t(TRANSLATIONS.accountingType),
				dataIndex: 'accountingType',
				renderer: ({ value }: RendererArgType) => (
					<div css={style.cell}>
						<Tooltip
							inline
							label={
								value === 0
									? t('settings.accountingSettings.doubleEntry')
									: t('settings.accountingSettings.taxEvidence')
							}
						>
							<Avatar size={24} backgroundColor={'#9FB2B6'}>
								{value === 0
									? t('settings.accountingSettings.doubleEntryShorthand')
									: t('settings.accountingSettings.taxEvidenceShorthand')}
							</Avatar>
						</Tooltip>
					</div>
				),
			},
			vatPayerType: {
				name: t(TRANSLATIONS.vatPayerType),
				dataIndex: 'vatPayerType',
				width: 175,
				renderer: ({ row }: RendererArgType) => <span>{formatOrganizationVATType(this.props.t, row)}</span>,
			},
			organizationName: {
				name: t(TRANSLATIONS.organizationName),
				dataIndex: 'organizationName',
			},
			organizationNameWithSelect_Stats: (selectOrganizationHandler: (orgId: string, orgName: string) => void) => {
				return {
					name: t(TRANSLATIONS.organizationName),
					dataIndex: 'organizationName',
					width: 220,
					renderer: ({ row }: RendererArgType) => {
						const orgSettings: ?OrganizationStatistics = row
						const cz: ?OrganizationStatisticsCountrySpecificCz =
							orgSettings && orgSettings.countrySpecificSettings && orgSettings.countrySpecificSettings.cz

						const taxRegistration: ?OrganizationCountrySpecificSettingsCzTaxRegistration = cz && cz.taxRegistration
						const authorization: ?OrganizationCountrySpecificSettingsCzAuthorization = cz && cz.authorization

						// const vatWarning =
						// 	row.report && row.report.shouldBecomeVatPayer ? (
						// 		<Tooltip inline label={t('internal.widget.organizationStatistics.vatLimitReached')}>
						// 			<Chip color="red">{`${t('settings.accountingDocuments.vatRate')} !`}</Chip>
						// 		</Tooltip>
						// 	) : null

						const contractIcon = <ContractIcon contractState={row.contractState} />

						const link = (
							<div css={style.link}>
								<TriviLink
									key={row.organizationId}
									label={t(TRANSLATIONS.organizationName)}
									onClick={() =>//eslint-disable-line
										row.organizationId
											? selectOrganizationHandler(row.organizationId, row.organizationName || '')
											: null
									}
								>
									{row.organizationName}
								</TriviLink>
							</div>
						)
						const taxRegistrationKeys: Array<string> = Object.keys(taxRegistration || {})
						const authorizationKeys: Array<string> = Object.keys(authorization || {})

						let taxRegistrationActive: boolean = false
						let authorizationActive: boolean = false

						taxRegistrationKeys.forEach(
							(key: string) =>
								(taxRegistrationActive =
									(taxRegistration && taxRegistration[key] && taxRegistration[key].isActive) || taxRegistrationActive),
						)

						authorizationKeys.forEach(
							(key: string) =>
								(authorizationActive =
									(authorization && authorization[key] && authorization[key].isActive) || authorizationActive),
						)

						if (taxRegistrationActive || authorizationActive) {
							const {
								incomeTax,
								roadTax,
								incomeTaxOnDependentActivity,
								naturalPersonIncomeWithholdingTax,
								securingTheTax,
								exciseDuty,
								immovablePropertyTax,
							} = taxRegistration || {}

							const { generalAuthorizationReceived, otherAuthorizationReceived } = authorization || {}

							const label = (
								<table className={styles.tooltipTable}>
									<tbody>
										<tr>
											<th>{t('settings.accountingSettings.taxRegistration')}</th>
											<th />
										</tr>
										{this.renderActivationDate('incomeTax', incomeTax)}
										{this.renderActivationDate('roadTax', roadTax)}
										{this.renderActivationDate('incomeTaxOnDependentActivity', incomeTaxOnDependentActivity)}
										{this.renderActivationDate('naturalPersonIncomeWithholdingTax', naturalPersonIncomeWithholdingTax)}
										{this.renderActivationDate('securingTheTax', securingTheTax)}
										{this.renderActivationDate('exciseDuty', exciseDuty)}
										{this.renderActivationDate('immovablePropertyTax', immovablePropertyTax)}
										<tr>
											<th>{t('settings.accountingSettings.authorization')}</th>
											<th />
										</tr>
										{this.renderActivationDate('generalAuthorizationReceived', generalAuthorizationReceived)}
										{this.renderActivationDate('otherAuthorizationReceived', otherAuthorizationReceived)}
									</tbody>
								</table>
							)
							return (
								<div css={style.organizationName}>
									<Tooltip inline label={label}>
										{link}
									</Tooltip>
									{/* {vatWarning} */}
									{contractIcon}
								</div>
							)
						}

						return (
							<div css={style.organizationName}>
								{link}
								{/* {vatWarning} */}
								{contractIcon}
							</div>
						)
					},
				}
			},
			hasFilledTurnover: {
				name: t('internal.widget.organizationStatistics.turnover'),
				dataIndex: 'report.hasFilledTurnover',
				renderer: ({ row }: RendererArgType) => {
					return row.report && row.report.hasFilledTurnover ? (
						<Tooltip inline label={t('internal.widget.organizationStatistics.mustFillTurnover')}>
							<Chip color="red">{t('internal.widget.organizationStatistics.turnover')}</Chip>
						</Tooltip>
					) : null
				},
			},
			vatLimitReached: {
				name: t('settings.accountingDocuments.vatRate'),
				dataIndex: 'report.shouldBecomeVatPayer',
				renderer: ({ row }: RendererArgType) => {
					if (!row.report || (!row.report.shouldBecomeVatPayer && !row.report.shouldBecomeVatPayerNow)) return null

					return row.report && row.report.shouldBecomeVatPayer ? (
						<div css={style.vatLimitReached}>
							{row.report.shouldBecomeVatPayerNow ? (
								<Tooltip
									inline
									label={t(
										'internal.widget.organizationStatistics.vatRegistrationNotification.immediateExceedingVatLimit',
									)}
								>
									<Chip color="red">{`${t('settings.accountingDocuments.vatRate')} !`}</Chip>
								</Tooltip>
							) : (
								<Tooltip
									inline
									label={t('internal.widget.organizationStatistics.vatRegistrationNotification.exceedingVatLimit')}
								>
									<Chip color="orange">{`${t('settings.accountingDocuments.vatRate')} !`}</Chip>
								</Tooltip>
							)}
						</div>
					) : null
				},
			},
			draftCount: {
				name: t(TRANSLATIONS.draftCount),
				dataIndex: 'stats.drafts',
				renderer: ({ row }: RendererArgType) => {
					return (
						<div css={style.cell}>
							<DocumentsDirectionTooltip
								organizationId={row.organizationId}
								renderValue={row.stats && row.stats.drafts}
								receivedValue={row.stats && row.stats.draftsReceived}
								issuedValue={row.stats && row.stats.draftsIssued}
								onFilterChange={this.props.onFilterChange}
								filter={[
									{
										field: 'state',
										value: 'Draft',
									},
								]}
							/>
						</div>
					)
				},
			},
			unmatchedPayments: {
				name: t(TRANSLATIONS.unmatchedPayments),
				dataIndex: 'stats.payments.unmatched.withResponses',
				renderer: ({ row }: RendererArgType) => {
					return (
						<div css={style.cell}>
							{row.organizationId && (
								<PaymentsTooltip
									filter={[]}
									organizationId={row.organizationId}
									renderValue={
										row.stats &&
										row.stats.payments &&
										row.stats.payments.unmatched &&
										row.stats.payments.unmatched.withResponses
									}
									onUnmatchedClick={() =>
										this.props.redirectToUnmatchedPaymentsRoute(row.organizationId, [
											{
												field: 'waitingFor',
												value: 1,
											},
											{
												field: 'isPaymentAlreadyMatched',
												value: false,
											},
										])
									}
									onMatchedClick={() =>
										this.props.redirectToMatchedPaymentsRoute(row.organizationId, [
											{
												field: 'waitingFor',
												value: 2,
											},
											{
												field: 'isPaymentAlreadyMatched',
												value: false,
											},
										])
									}
								/>
							)}
						</div>
					)
				},
			},
			cashRegisters: {
				name: 'Pokladny',
				dataIndex: 'stats.cashRegisters',
				renderer: ({ row }: RendererArgType) => {
					return (
						<div css={style.cell}>
							<CashRegistersTooltip
								organizationId={row.organizationId}
								cashRegisters={row.stats && row.stats.cashRegisters}
								onRedirect={this.props.redirectToCashRegisterRoute}
							/>
						</div>
					)
				},
			},
			extractedDrafts: {
				name: t(TRANSLATIONS.extractedDrafts),
				dataIndex: 'stats.extractedDrafts',
				renderer: ({ row }: RendererArgType) => {
					return (
						<div css={style.cell}>
							<ExtractedDocumentsDirectionTooltip
								organizationId={row.organizationId}
								renderValue={row.stats && row.stats.extractedDrafts}
								receivedValue={row.stats && row.stats.extractedDraftsReceived}
								issuedValue={row.stats && row.stats.extractedDraftsIssued}
								onFilterChange={this.props.onFilterChange}
								cashRegisters={row.stats && row.stats.cashRegisters}
								onRedirect={this.props.redirectToCashRegisterRoute}
								filter={[
									{
										field: 'state',
										value: 'Extracted',
									},
								]}
							/>
						</div>
					)
				},
			},
			errorCount: {
				name: t(TRANSLATIONS.errorCount),
				dataIndex: 'stats.processingErrors',
				renderer: this.getStatRender('processingErrors'),
			},
			travelOrders: {
				name: t(TRANSLATIONS.travelOrders),
				dataIndex: 'stats.travelOrders',
				renderer: this.getStatRender('travelOrders'),
			},
			bankStatements: {
				name: t(TRANSLATIONS.bankStatements),
				dataIndex: 'stats.bankStatements',
				renderer: ({ row }: RendererArgType) => {
					const value = row.stats == null ? 0 : row.stats['bankStatements'] || 0
					let bankAccounts: ?OrganizationStatisticsKeysBankAccounts = row.stats
						? row.stats.bankAccounts
							? row.stats.bankAccounts
							: null
						: null
					if (!bankAccounts || !value) return <div css={style.cell}>{value}</div>
					return (
						<div css={style.cell}>
							<Tooltip
								hoverable
								inline
								label={
									<StatementsSummary
										organizationId={row.organizationId || ''}
										bankAccounts={bankAccounts}
										handleStatementClick={redirectToNoProcessedStatements}
									/>
								}
							>
								{value}
							</Tooltip>
						</div>
					)
				},
				// renderer: this.getStatRender('bankStatements'),
			},
			unknowns: {
				name: t(TRANSLATIONS.unknowns),
				dataIndex: 'stats.unknowns',
				renderer: this.getStatRender('unknowns'),
			},
			fixingCount: {
				name: t(TRANSLATIONS.fixingCount),
				dataIndex: 'stats.fixing',
				renderer: this.getStatRender('fixing'),
			},
			waitingForApproval: {
				name: t(TRANSLATIONS.processingWaiting),
				dataIndex: 'stats.accountingDocuments.processingWaiting',
				renderer: ({ row }: RendererArgType) => {
					const val =
						row.stats &&
						row.stats.accountingDocuments &&
						row.stats.accountingDocuments &&
						row.stats.accountingDocuments.processingWaiting
							? row.stats.accountingDocuments.processingWaiting
							: {}
					return (
						<div css={style.cell}>
							<DocumentsDirectionTooltip
								organizationId={row.organizationId}
								renderValue={val.count || 0}
								receivedValue={val.receivedCount || 0}
								issuedValue={val.issuedCount || 0}
								onFilterChange={this.props.onFilterChange}
								filter={[
									{
										field: 'processingState',
										value: 2,
									},
								]}
							/>
						</div>
					)
				},
			},
			needHelp: {
				name: t(TRANSLATIONS.needHelp),
				dataIndex: 'stats.accountingDocuments.needHelp.count',
				renderer: ({ row }: RendererArgType) => {
					const val =
						(row.stats &&
							row.stats.accountingDocuments &&
							row.stats.accountingDocuments &&
							row.stats.accountingDocuments.needHelp) ||
						{}

					const cashRegisterTotal =
						row.stats &&
						row.stats.cashRegisters &&
						row.stats.cashRegisters.cashRegisters &&
						row.stats.cashRegisters.cashRegisters.reduce(
							(result: number, item: OrganizationStatisticsKeysCashRegister) => {
								const count =
									(item.accountingDocuments &&
										item.accountingDocuments.needHelp &&
										item.accountingDocuments.needHelp.count) ||
									0
								return result + count
							},
							0,
						)

					return (
						<div css={style.cell}>
							<NeedHelpTooltip
								organizationId={row.organizationId}
								renderValue={(val.count || 0) + cashRegisterTotal}
								receivedValue={val.receivedCount || 0}
								issuedValue={val.issuedCount || 0}
								unknownsValue={val.unknownsCount || 0}
								onFilterChange={this.props.onFilterChange}
								cashRegisters={row.stats && row.stats.cashRegisters}
								onRedirect={this.props.redirectToCashRegisterRoute}
							/>
						</div>
					)
				},
			},
		}
	}

	renderActivationDate(field: string, activationDate: ?ActivationWithStartDate) {
		const { t } = this.props
		return (
			activationDate &&
			activationDate.isActive && (
				<tr>
					<td>{t(`settings.accountingSettings.${field}`)}</td>
					<td>{activationDate && activationDate.startDate && formatToDateString(activationDate.startDate)}</td>
				</tr>
			)
		)
	}

	render() {
		const { t, changeCurrentOrganization, accountingDocumentsCountToExtract } = this.props
		const {
			organizationNameWithSelect_Stats: organizationNameWithSelect,
			draftCount,
			extractedDrafts,
			errorCount,
			fixingCount,
			//travelOrders,
			bankStatements,
			vatLimitReached,
			hasFilledTurnover,
			unknowns,
			accountingType,
			vatPayerType,
			waitingForApproval,
			needHelp,
			cashRegisters,
			unmatchedPayments,
		} = this.getColumns()

		const selectOrganization = (orgId: string, orgName: string) => {
			changeCurrentOrganization(orgId).then(() => {
				this.props.notify(t('internal.widget.organizationSelected', { orgName }), 'success')
				this.gridRefresh
			})
		}
		let columns = [organizationNameWithSelect(selectOrganization), extractedDrafts]
		if (!this.props.isInternalExactUser) {
			columns = [
				organizationNameWithSelect(selectOrganization),
				hasFilledTurnover,
				vatLimitReached,
				unknowns,
				draftCount,
				waitingForApproval,
				unmatchedPayments,
				needHelp,
				cashRegisters,
				// travelOrders, // Hidden according to TESTREP-1604
				bankStatements,
				errorCount,
				fixingCount,
				extractedDrafts,
				accountingType,
				vatPayerType,
			]
		}

		const gridConf = {
			getRefresh: (refresh: Function) => {
				this.gridRefresh = refresh
			},
			columns,
			stateKey: 'organizationStatisticsGrid',
			apiFunction: organizationsStats.post,
			dataFieldName: 'organizationStatistics',
		}

		return (
			<div className={styles.root}>
				<div className={styles.header}>
					<h4 className={styles.headline}>{t(TRANSLATIONS.statsHeader)}</h4>
					{this.props.showExtract && (
						<div css={style.toExtract}>
							{accountingDocumentsCountToExtract > 0 ? (
								<Button
									labelText={t(TRANSLATIONS.beginExtraction, { count: accountingDocumentsCountToExtract })}
									onClick={this.props.extractNextAccountingDocument}
								/>
							) : (
								t(TRANSLATIONS.nothingToExtract)
							)}
						</div>
					)}
				</div>
				<DataGrid {...gridConf} pageSize={8} border filter={this.props.filter} />
			</div>
		)
	}
}

const style = {
	organizationName: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	hasFilledTurnover: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
	},
	vatLimitReached: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
	},
	link: {
		marginRight: 10,
	},
	toExtract: {
		marginLeft: 50,
		fontWeight: 'bold',
		color: colors.green,
	},
	cell: {
		textAlign: 'right',
		paddingRight: 5,
	},
}

export default withTranslate(withNotify(OrganizationStatisticsGrid))

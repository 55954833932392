// @flow

import type {
	AccountingDocumentReportAggregationResponseAggregatedItem as AggregatedItem,
	Currency,
	OrganizationReport,
	OrganizationSettingsOpen,
	State,
} from 'types'
import type { BalanceSummaryItem, BillingSummaryFilter, SummaryType } from '../types'
import type { Categories as ColumnChartCategories, Data as ColumnChartData } from 'components/charts/column-chart'
import { convertCategories, convertData } from 'modules/dashboard/domain/billing-summary'

import type { State as BillingSummaryState } from 'modules/dashboard/reducers/billing-summary'
import { convertToDomesticKey } from '../constants'
import { createSelector } from 'reselect'

export const getIsBillingSummaryLoading: (state: State) => boolean = (state: State) =>
	state.dashboard.billingSummary.loading

export const getBillingSummaryFilter: (state: State) => BillingSummaryFilter = createSelector(
	(state: State) => state.dashboard.billingSummary,
	getUserDashboardCurrency,
	getBillingSummaryFilterPure,
)

export function getBillingSummaryFilterPure(
	billingSummary: BillingSummaryState,
	currency: ?string,
): BillingSummaryFilter {
	return { ...billingSummary.filter, currency }
}

export const getBillingSummary: (state: State) => ?Array<AggregatedItem> = createSelector(
	(state: State) => state.dashboard.billingSummary,
	getBillingSummaryFilter,
	getBillingSummaryPure,
)

export function getBillingSummaryPure(
	billingSummary: BillingSummaryState,
	filter: BillingSummaryFilter,
): ?Array<AggregatedItem> {
	const { currency, interval, timeGranularity } = filter
	const currencyOrAll = currency || convertToDomesticKey

	if (!interval || !timeGranularity) {
		return undefined
	}

	const intervalKey = JSON.stringify(interval)

	return (
		billingSummary.data[currencyOrAll] &&
		billingSummary.data[currencyOrAll][intervalKey] &&
		billingSummary.data[currencyOrAll][intervalKey][timeGranularity]
	)
}

export const getBillingSummaryChartData: (state: State) => ?ColumnChartData = createSelector(
	getBillingSummary,
	getBillingSummaryChartDataPure,
)

export function getBillingSummaryChartDataPure(billingSummary: ?Array<AggregatedItem>): ?ColumnChartData {
	return billingSummary && convertData(billingSummary)
}

export const getBillingSummaryChartCategories: (state: State) => ?ColumnChartCategories = createSelector(
	getBillingSummary,
	getBillingSummaryChartCategoriesPure,
)

export function getBillingSummaryChartCategoriesPure(billingSummary: ?Array<AggregatedItem>): ?ColumnChartCategories {
	return billingSummary && convertCategories(billingSummary)
}

export function getBalanceSummary(state: State, type: SummaryType, currency: string): ?BalanceSummaryItem {
	return state.dashboard.balanceSummary.data[type] && state.dashboard.balanceSummary.data[type][currency]
}

export function getIsBalanceSummaryLoading(state: State): boolean {
	return state.dashboard.balanceSummary.loading
}

export function getCurrency(state: State): ?Currency {
	return state.dashboard.currency.activeCurrency
}

export function getUserDashboardCurrency(state: State): ?string {
	const currency = getCurrency(state)
	return currency && currency.id
}

export function getOrganizationSettingsOpen(state: State): ?OrganizationSettingsOpen {
	return state.settings.organizationSettingsOpen
}

export function getOrganizationReport(state: State): ?OrganizationReport {
	return state.organization.organizationDetail.data && state.organization.organizationDetail.data.report
}

export function getOrganizationVatPayerType(state: State): ?number {
	return state.organization.organizationDetail.data && state.organization.organizationDetail.data.payerTaxType
}

export * from './invitation'
export * from './cards'
export * from './current-turnover'

/* @flow */

import type { OrganizationReport, OrganizationReportVatPayment, State } from 'types'
import {
	getOrganizationReport,
	getOrganizationReportVatPayment,
	getOrganizationVatPayerType,
} from '../../dashboard/selectors'

import VatRegistration from '../components/vat-registration'
import { connect } from 'react-redux'

type StateProps = {|
	shouldBecomeVatPayer: ?boolean,
	shouldBecomeVatPayerNow: ?boolean,
	organizationVatPayerType: ?number,
|}

const mapStateToProps = (state: State): StateProps => {
	let shouldBecomeVatPayer = false
	let shouldBecomeVatPayerNow = false

	const dashboardReport: ?OrganizationReportVatPayment = getOrganizationReportVatPayment(state)
	if (dashboardReport) {
		shouldBecomeVatPayer = dashboardReport.shouldBecomeVatPayer
		shouldBecomeVatPayerNow = dashboardReport.shouldBecomeVatPayerNow
	} else {
		const organizationReport: ?OrganizationReport = getOrganizationReport(state)
		if (organizationReport) {
			shouldBecomeVatPayer = organizationReport.shouldBecomeVatPayer
			shouldBecomeVatPayerNow = organizationReport.shouldBecomeVatPayerNow
		}
	}
	const organizationVatPayerType = getOrganizationVatPayerType(state)

	return {
		shouldBecomeVatPayer: shouldBecomeVatPayer,
		shouldBecomeVatPayerNow: shouldBecomeVatPayerNow,
		organizationVatPayerType: organizationVatPayerType,
	}
}

export default connect(mapStateToProps)(VatRegistration)

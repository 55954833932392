/* @flow */
/** @jsx jsx */

import type { Filter as GridFilter, Organization, UserProfile } from 'types'
import { Tab, Tabs } from 'components/Tabs'
import { type WithNotifyProps, type WithTranslateProps, withNotify, withTranslate } from 'wrappers'

import Avatar from 'components/avatar'
import Chip from 'components/chip'
import { Column } from '../../data-grid-next/components'
import { Component } from 'react'
import ContractIcon from 'modules/common/components/contract-icon'
import { DataGrid as DataGridNext } from '../../data-grid-next/containers'
import type { DataGridProps as DataGridNextProps } from '../../data-grid-next/containers'
import EsoIcon from 'components/eso-icon'
import OrganizationAdvancedFilter from './organization-advanced-filter'
import Tooltip from 'components/tooltip'
import TriviLink from 'modules/common/components/trivi-link'
import UserProfileAvatar from 'modules/user/components/user-icon/user-profile-avatar'
import { getUserDisplayName } from 'modules/user/user/user'
import { jsx } from '@emotion/core'
import styles from './organization-overview.css'

const TRANSLATION_PREFIX = 'internal.widget.organizationOverview.'

const TRANSLATIONS = {
	companyName: TRANSLATION_PREFIX + 'companyName',
	accountingType: TRANSLATION_PREFIX + 'accountingType',
	internalUsers: TRANSLATION_PREFIX + 'internalUsers',
	payerTaxType: TRANSLATION_PREFIX + 'payerTaxType',
	selectOrganization: TRANSLATION_PREFIX + 'selectOrganization',
	myOrganizations: TRANSLATION_PREFIX + 'myOrganizations',
	all: TRANSLATION_PREFIX + 'all',
	VATPayer: TRANSLATION_PREFIX + 'VATPayer',
	VATFree: TRANSLATION_PREFIX + 'VATFree',
	Identified: TRANSLATION_PREFIX + 'Identified',
	vatLimit: TRANSLATION_PREFIX + 'vatLimit',
}

type RendererArgType = {
	column: any,
	value: string,
	row: Organization,
}

type Filter = 0 | 1 | 2 | 3 | 4

const MY_ORGANIZATION_FITLER = 0
const ALL_FILTER = 1
const VAT_PAYER_FILTER = 2
const VAT_FREE_FILTER = 3
const IDENTIFIED_FILTER = 4

export type Props = {|
	...WithNotifyProps,
	...WithTranslateProps,
	changeCurrentOrganization: (id: string) => Promise<void>,
	currentUser: ?UserProfile,
|}

type State = {
	activeFilter: Filter,
	isFilterOpen: boolean,
}

const OrganizationsDataGrid: React$ComponentType<DataGridNextProps<'organizations'>> = DataGridNext

const GRID_ID = 'organizationOverviewGrid'

class OrganizationOverview extends Component<Props, State> {
	state: State = {
		activeFilter: MY_ORGANIZATION_FITLER,
		isFilterOpen: false,
	}

	gridRefresh: Function = () => {}
	mountLoaderUsed: boolean = false

	handleTabChange = (activeFilter: Filter) => {
		this.setState({ activeFilter })
	}

	handleFilterOpen = () => {
		this.setState({ isFilterOpen: true })
	}

	handleFilterClose = () => {
		this.setState({ isFilterOpen: false })
	}

	getCurrentFilter(): GridFilter {
		switch (this.state.activeFilter) {
			case MY_ORGANIZATION_FITLER:
				return [
					{
						field: 'internalUsers.userId',
						...(this.props.currentUser != null ? { value: this.props.currentUser.id } : {}),
					},
				]
			case VAT_PAYER_FILTER:
				return [
					{
						field: 'payerTaxType',
						value: 0,
					},
				]
			case VAT_FREE_FILTER:
				return [
					{
						field: 'payerTaxType',
						value: 1,
					},
				]
			case IDENTIFIED_FILTER:
				return [
					{
						field: 'payerTaxType',
						value: 2,
					},
				]
			default:
				return []
		}
	}

	getColumns() {
		const { t } = this.props

		return {
			companyName: {
				name: t(TRANSLATIONS.companyName),
				dataIndex: 'name',
				width: '',
			},
			accountingType: {
				name: t(TRANSLATIONS.accountingType),
				dataIndex: 'accountingType',
				width: '130px',
				renderer: ({ value }: RendererArgType) => (
					<Tooltip
						label={
							value === 0 ? t('settings.accountingSettings.doubleEntry') : t('settings.accountingSettings.taxEvidence')
						}
						inline
					>
						<Avatar size={24} backgroundColor={'#9FB2B6'}>
							{value === 0
								? t('settings.accountingSettings.doubleEntryShorthand')
								: t('settings.accountingSettings.taxEvidenceShorthand')}
						</Avatar>
					</Tooltip>
				),
			},
			payerTaxType: {
				name: t(TRANSLATIONS.payerTaxType),
				dataIndex: 'payerTaxType',
				width: '',
			},
		}
	}

	organizationNameRenderer = (value: string, row: any) => {
		return (
			<div>
				<TriviLink
					key={row.id}
					label={this.props.t(TRANSLATIONS.companyName)}
					onClick={() => { //eslint-disable-line
						row.id &&
							this.props.changeCurrentOrganization(row.id).then(() => {
								this.props.notify(
									this.props.t('internal.widget.organizationSelected', { orgName: row.name || '' }),
									'success',
								)
								this.gridRefresh()
							})
					}}
				>
					{row.name || ''}
				</TriviLink>
				<div css={style.icons}>
					<EsoIcon state={row.connectionState} />
					<ContractIcon contractState={row.contractState} />
				</div>
			</div>
		)
	}

	vatLimitRenderer = (value: string, row: any) => {
		if (!row.report || (!row.report.shouldBecomeVatPayer && !row.report.shouldBecomeVatPayerNow)) return null

		const t = this.props.t

		return (
			<div>
				{row.report.shouldBecomeVatPayerNow ? (
					<Tooltip
						inline
						label={t('internal.widget.organizationStatistics.vatRegistrationNotification.immediateExceedingVatLimit')}
					>
						<Chip color="red">{`${t('settings.accountingDocuments.vatRate')} !`}</Chip>
					</Tooltip>
				) : (
					<Tooltip
						inline
						label={t('internal.widget.organizationStatistics.vatRegistrationNotification.exceedingVatLimit')}
					>
						<Chip color="orange">{`${t('settings.accountingDocuments.vatRate')} !`}</Chip>
					</Tooltip>
				)}
			</div>
		)
	}

	internalUsersRenderer = (internalUsers: Array<any>) => {
		if (internalUsers && internalUsers.length) {
			return (
				<div>
					{(internalUsers || []).map(
						(
							user: {
								userId?: string,
								firstname?: string,
								lastname?: string,
								email?: string,
								membershipType?: number,
							},
							index: number,
						) => {
							return (
								<Tooltip
									wrapperStyle={style.tooltip}
									key={index}
									inline
									label={
										<span>
											{getUserDisplayName(user.firstname, user.lastname, user.email)}
											{user.membershipType && (
												<div>{this.props.t(`membershipType.${user.membershipType}.singular`)}</div>
											)}
										</span>
									}
								>
									<UserProfileAvatar size={30} name={getUserDisplayName(user.firstname, user.lastname)} />
								</Tooltip>
							)
						},
					)}
				</div>
			)
		} else return null
	}

	render() {
		const { t, currentUser } = this.props

		if (!currentUser) {
			return null
		}

		return (
			<div className={this.state.isFilterOpen ? styles.opened : styles.normal}>
				<div className={styles.head}>
					<div className={styles.tabs}>
						<Tabs
							large
							tall
							shrink
							tabItemContainerStyle={{ borderBottom: 'none' }}
							value={this.state.activeFilter}
							onChange={this.handleTabChange}
						>
							<Tab label={t(TRANSLATIONS.myOrganizations)} value={MY_ORGANIZATION_FITLER} />
							<Tab label={t(TRANSLATIONS.all)} value={ALL_FILTER} />
							<Tab label={t(TRANSLATIONS.VATPayer)} value={VAT_PAYER_FILTER} />
							<Tab label={t(TRANSLATIONS.VATFree)} value={VAT_FREE_FILTER} />
							<Tab label={t(TRANSLATIONS.Identified)} value={IDENTIFIED_FILTER} />
						</Tabs>
					</div>
					<div className={styles.filter}>
						<OrganizationAdvancedFilter
							filterName={GRID_ID}
							onOpen={this.handleFilterOpen}
							onClose={this.handleFilterClose}
							defaultFilter={this.getCurrentFilter()}
						/>
					</div>
				</div>
				<div className={styles.grid}>
					<OrganizationsDataGrid
						id={GRID_ID}
						dataType="organizations"
						filterId={GRID_ID}
						defaultFilter={this.getCurrentFilter()}
						defaultPageSize={5}
					>
						<Column
							title={t(TRANSLATIONS.companyName)}
							width=""
							columnId="name"
							key="name"
							render={this.organizationNameRenderer}
							sortable
							defaultSortDirection="ASC"
						/>

						<Column
							title={t(TRANSLATIONS.vatLimit)}
							width="40%"
							columnId="report.shouldBecomeVatPayer"
							key="report.shouldBecomeVatPayer"
							sortable
							render={this.vatLimitRenderer}
						/>
						<Column
							title={t(TRANSLATIONS.internalUsers)}
							width=""
							columnId="internalUsers"
							key="internalUsers"
							render={this.internalUsersRenderer}
						/>
					</OrganizationsDataGrid>
				</div>
			</div>
		)
	}
}

const style = {
	tooltip: { marginRight: 8 },
	icons: {
		marginTop: 3,
		'> *': {
			marginRight: 5,
		},
	},
}

export default withTranslate(withNotify(OrganizationOverview))

/* @flow */
/** @jsx jsx */

import { type WithTranslateProps, withTranslate } from 'wrappers'

import { Component } from 'react'
import InfoIcon from 'components/svg-icons/action/info-outline'
import { type OrganizationReportVatPayment } from 'types'
import PopupSection from 'components/PopupSection'
import Section from '../todo/section'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'
import { formatToMoney } from 'utils/formatters'
import { jsx } from '@emotion/core'
import moment from 'moment'

type Props = {|
	...WithTranslateProps,
	vatPayment: ?OrganizationReportVatPayment,
	currency: ?string,
	taxPayer: boolean,
|}

type State = {|
	dialogOpen: boolean,
|}

type BarColor = 'blue' | 'orange' | 'red'

class CurrentTurnover extends Component<Props, State> {
	state = {
		dialogOpen: false,
	}

	render() {
		if (this.props.taxPayer) {
			return null
		}

		const stylesMain = getStylesMain()

		const {
			currentTurnover = 0,
			lastYearTurnover = 0,
			maxTurnoverForMandatoryVatRegistrationNextYear = 0,
			maxTurnoverForMandatoryVatRegistrationImmediately = 0,
			currentTurnoverFromSettings = 0,
			lastYearTurnoverFromSettings = 0,
			yearThresholdForImmediately = 0,
		} = this.props.vatPayment || {}

		const thisYear = moment().year()
		const lastYear = thisYear - 1

		let maxTurnover =
			lastYearTurnover >= maxTurnoverForMandatoryVatRegistrationNextYear && lastYear >= yearThresholdForImmediately
				? maxTurnoverForMandatoryVatRegistrationImmediately
				: maxTurnoverForMandatoryVatRegistrationNextYear

		let barColor = this.getBarColor(lastYearTurnover, maxTurnoverForMandatoryVatRegistrationNextYear, maxTurnover)

		const barForLastYear = this.renderBarSection(
			lastYearTurnover,
			maxTurnover,
			lastYear,
			lastYearTurnoverFromSettings,
			barColor,
		)

		maxTurnover =
			currentTurnover >= maxTurnoverForMandatoryVatRegistrationNextYear && thisYear >= yearThresholdForImmediately
				? maxTurnoverForMandatoryVatRegistrationImmediately
				: maxTurnoverForMandatoryVatRegistrationNextYear

		barColor = this.getBarColor(currentTurnover, maxTurnoverForMandatoryVatRegistrationNextYear, maxTurnover)

		const barForThisYear = this.renderBarSection(
			currentTurnover,
			maxTurnover,
			thisYear,
			currentTurnoverFromSettings,
			barColor,
		)

		const infoText = <div css={stylesMain.infoText}>{this.props.t('dashboard.todo.currentTurnover.infoText')}</div>

		return (
			<Section header={this.props.t('dashboard.todo.currentTurnover.title')}>
				{barForLastYear}
				{barForThisYear}
				{infoText}
				{this.renderDialog()}
			</Section>
		)
	}

	getBarColor(turnover: number, maxTurnoverForMandatoryVatRegistrationNextYear: number, maxTurnover: number) {
		if (maxTurnoverForMandatoryVatRegistrationNextYear === maxTurnover) {
			return turnover > maxTurnover ? 'red' : 'blue'
		}

		if (turnover > maxTurnoverForMandatoryVatRegistrationNextYear && turnover > maxTurnover) {
			return 'red'
		} else if (turnover > maxTurnoverForMandatoryVatRegistrationNextYear && turnover <= maxTurnover) {
			return 'orange'
		}

		return 'blue'
	}

	warningSection(year: number, turnover: number, limit: number, styles: Object) {
		const { maxTurnoverForMandatoryVatRegistrationNextYear = 0 } = this.props.vatPayment || {}
		const remainingPart = limit - turnover
		const isOverflowYearLimit = turnover > maxTurnoverForMandatoryVatRegistrationNextYear

		const limitFormatted = formatToMoney(limit, {
			currency: this.props.currency,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		})

		const remainingPartFormatted = formatToMoney(Math.abs(remainingPart), {
			currency: this.props.currency,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		})

		const yearLimitFormatted = formatToMoney(Math.abs(maxTurnoverForMandatoryVatRegistrationNextYear), {
			currency: this.props.currency,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		})

		return (
			<div css={styles.info}>
				<InfoIcon size={18} style={styles.infoIcon} disabled />
				<span>
					{this.props.t(
						remainingPart < 0
							? 'dashboard.todo.currentTurnover.exceededLimitInfo'
							: isOverflowYearLimit
							? 'dashboard.todo.currentTurnover.yearLimitInfo'
							: 'dashboard.todo.currentTurnover.limitInfo',
						{
							year: year,
							remainingPart: remainingPartFormatted,
							limit: limitFormatted,
							yearLimit: yearLimitFormatted,
						},
					)}
				</span>
			</div>
		)
	}

	turnoverFromSettingsSection(turnoverFromSettings: number, styles: Object) {
		let turnoverFromSettingsFormatted = formatToMoney(turnoverFromSettings, {
			currency: this.props.currency,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		})
		let labelText = this.props.t('dashboard.todo.currentTurnover.turnoverFromSettings', {
			turnoverFromSettings: turnoverFromSettingsFormatted,
		})
		return (
			<div css={styles.turnoverSettings}>
				<Tooltip label={labelText} multiLine wrapperStyle={styles.tooltip}>
					<InfoIcon size={18} style={styles.infoIcon} disabled />
					<span> + {turnoverFromSettingsFormatted} </span>
				</Tooltip>
			</div>
		)
	}

	renderRightHeader(currentTurnoverLimitPercent: number, styles: Object) {
		return (
			<div style={styles.info}>
				<InfoIcon size={18} style={styles.infoIcon} disabled />
				<span>
					{this.props.t('dashboard.todo.currentTurnover.exceededLimitInfo', {
						percent: currentTurnoverLimitPercent,
					})}
					&nbsp;
				</span>
				{/*<TriviLink onClick={this.openDialog} style={styles.link}>*/}
				{/*	{this.props.t('dashboard.todo.currentTurnover.exceededLimitMoreInfo')}*/}
				{/*</TriviLink>*/}
			</div>
		)
	}

	renderBarSection(
		turnover: number,
		maxTurnover: number,
		year: number,
		turnoverFromSettings: number,
		barColor: BarColor,
	) {
		const turnoverLimitPercent = Math.floor((turnover / maxTurnover) * 100)
		const overflowed = turnoverLimitPercent >= 100
		const showWarning = turnoverLimitPercent >= 60
		const showTurnoverFromSettings = turnoverFromSettings > 0
		const styles = getStyles(turnoverLimitPercent, barColor)

		const turnoverInfo = (
			<div>
				<div css={styles.type}>{this.props.t('dashboard.todo.currentTurnover.turnover')}</div>
				<div css={styles.amount}>
					{formatToMoney(turnover, {
						currency: this.props.currency,
						maximumFractionDigits: 0,
						minimumFractionDigits: 0,
					})}
				</div>
			</div>
		)

		const limitInfo = (
			<div>
				<div css={styles.type}>{this.props.t('dashboard.todo.currentTurnover.limit')}</div>
				<div css={styles.amount}>
					{formatToMoney(maxTurnover, {
						currency: this.props.currency,
						maximumFractionDigits: 0,
						minimumFractionDigits: 0,
					})}
				</div>
			</div>
		)

		return (
			<div style={{ marginBottom: '20px' }}>
				<div css={styles.container_bar}>
					<div css={styles.year}>{year}</div>
					<div css={styles.bar}>
						<div css={styles.turnover}>{overflowed ? limitInfo : turnoverInfo}</div>
						<div css={styles.limit}>{overflowed ? turnoverInfo : limitInfo}</div>
					</div>
				</div>
				<div css={styles.infoText}>
					{showTurnoverFromSettings && this.turnoverFromSettingsSection(turnoverFromSettings, styles)}
					{showWarning && this.warningSection(year, turnover, maxTurnover, styles)}
				</div>
			</div>
		)
	}

	renderDialog() {
		return (
			<PopupSection open={this.state.dialogOpen} modal onRequestClose={this.closeDialog}>
				Info content.
			</PopupSection>
		)
	}

	openDialog = () => {
		this.setState({ dialogOpen: true })
	}

	closeDialog = () => {
		this.setState({ dialogOpen: false })
	}
}

function getStylesMain() {
	return {
		infoText: {
			display: 'flex',
			margin: '0px 20px 20px 20px',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: 'smaller',
		},
	}
}

function getStyles(currentTurnoverLimitPercent: number, barColor: BarColor) {
	const overflowed = currentTurnoverLimitPercent >= 100
	const width = currentTurnoverLimitPercent
		? overflowed
			? (100 / currentTurnoverLimitPercent) * 100
			: currentTurnoverLimitPercent
		: 0

	return {
		infoText: {
			display: 'flex',
			width: '100%',
			flexDirection: 'row',
			justifyContent: 'space-evenly',
		},
		turnoverSettings: {
			alignItems: 'center',
			margin: '0 30px 5px',
		},
		info: {
			alignItems: 'center',
			margin: '0 30px 5px',
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
		infoIcon: {
			verticalAlign: 'middle',
			marginRight: 5,
			color: 'red',
		},
		link: {
			textDecoration: 'underline',
		},
		type: {
			fontSize: 14,
			lineHeight: '23px',
		},
		amount: {
			fontSize: 16,
			lineHeight: '23px',
			fontWeight: 600,
		},
		year: {
			color: '#000000',
			fontSize: '37px',
			width: '10%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: '0 0px 5px 20px',
		},
		container_bar: {
			display: 'flex',
		},
		bar: {
			display: 'flex',
			background:
				barColor === 'red'
					? 'linear-gradient(90deg, rgba(219,218,245,1) 0%, rgba(232,31,0,1) 100%)'
					: barColor === 'orange'
					? 'linear-gradient(90deg, rgba(219,218,245,1) 0%, rgba(232,132,0,1) 100%)'
					: '#DAE9F4',
			borderRadius: 20,
			overflow: 'hidden',
			margin: '0 20px 5px 20px',
			textAlign: 'right',
			width: '90%',
		},
		turnover: {
			flex: `0 1 ${width}%`,
			background: overflowed ? colors.transparent : '#6E7798',
			borderRadius: overflowed ? 0 : 20,
			borderRight: overflowed ? `1px dashed ${colors.whiteFaded80}` : 'none',
			overflow: 'hidden',
			padding: '9px 30px',
			color: colors.whiteFaded80,
			minWidth: overflowed ? 200 : 130,
		},
		limit: {
			flex: '1 0 auto',
			padding: '9px 30px 9px 12px',
			color: overflowed ? colors.white : colors.black,
		},
	}
}

export default withTranslate(CurrentTurnover)
